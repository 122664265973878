// helpers
function calculateMinHeight() {
  document.documentElement.style.setProperty('--body-min-height', window.innerHeight + 'px');
  window.addEventListener('resize', calculateMinHeight);
}

function isSafari() {
  const agent = navigator.userAgent.toLowerCase();

  return agent.includes('safari') && !agent.includes('chrome');
}

// libs init
function initSimpleBar() {
  const simplebar = new SimpleBar(document.getElementById('table-main-wrapper'));
  window.addEventListener('resize', () => {
    simplebar.recalculate()
  })
}

function initSounds() {
  soundMain = new Howl({ src: ['./sounds/main.mp3'] });
  soundBlob = new Howl({ src: ['./sounds/blob.mp3'] });

  console.log(soundMain)
  console.log(soundBlob)

  const btn = document.getElementById('btn-sound');
  btn.classList.remove('off');

  btn.addEventListener('click', () => {
    btn.classList.toggle('off');
    isSoundOn = isSoundOn ? false : true;

    if (isSoundOn) {
      Howler.volume(0.7);
      soundMain.play();
    } else {
      Howler.volume(0);
      Howler.stop()
    }
  })
}

// controll
function closePage(pageId) {
  document.getElementById(`${pageId}`).classList.remove('active');
}

function openPage(pageId) {
  document.getElementById(`${pageId}`).classList.add('active');
}

function startPageInit() {
  const startBtn = document.getElementById('page-start-btn');

  startBtn.addEventListener('click', () => {
    closePage('page-start');
    openPage('page-game');
    openPage('game-menu');

    if (!isSoundInit) {
      initSounds();
      isSoundInit = true;
    }
  })
}

// game
function closeAllGames() {
  for (let i = 1; i <= QUANTITY_GAMES; i++) {
    document.getElementById(`game-${i}`).classList.remove('active');
  }
}

function openGame(gameNumber) {
  closeAllGames();
  document.getElementById(`game-${gameNumber}`).classList.add('active');
}

function closeGameControll() {
  document.getElementById('game-controll').classList.remove('active');
}

function openGameControll(gameNumber) {
  document.getElementById('game-controll').classList.add('active');

  const gameBarTargets = document.querySelectorAll('.game-bar-target');
  gameBarTargets.forEach(el => {
    el.classList.remove('show');
  });

  for (let i = 1; i <= QUANTITY_GAMES; i++) {
    document.getElementById(`game-bar-targets-${i}`).classList.remove('show');
  }

  document.getElementById(`game-bar-targets-${gameNumber}`).classList.add('show');
}

function renderTimer(time) {
  const clockRank1 = document.getElementById('clock-rank-1');
  const clockRank2 = document.getElementById('clock-rank-2');
  const clockRank3 = document.getElementById('clock-rank-3');
  const clockRank4 = document.getElementById('clock-rank-4');

  const min = Math.floor(time / 60) % 60;
  const sec = time % 60;

  clockRank1.innerText = `${Math.floor(min / 10)}`;
  clockRank2.innerText = `${Math.floor(min % 10)}`;
  clockRank3.innerText = `${Math.floor(sec / 10)}`;
  clockRank4.innerText = `${Math.floor(sec % 10)}`;
}

function stopTimer() {
  clearInterval(timerInterval);
}

function startTimer() {
  currentTime = GAME_TIME;
  renderTimer(currentTime);

  timerInterval = setInterval(() => {
    if (currentTime < 1) {
      stopTimer();
      currentTime = 0;
      gameTimeExpired();
    } else {
      currentTime--;
    }

    renderTimer(currentTime);
  }, 1000);
}

function gameTimeExpired() {
  openPage('popup-time-over');
}

function embedGamePopup(target, top = true, right = true, text = '') {
  const popup = document.createElement('div');
  const popupClose = document.createElement('button');
  const popupDescr = document.createElement('div');

  popup.append(popupClose);
  popup.append(popupDescr);

  popup.classList.add('game-popup');
  popupClose.classList.add('game-popup-close');
  popupDescr.classList.add('game-popup-descr');

  const popupStyle = `${top ? 'top' : 'bottom'}-${right ? 'right' : 'left'}`
  popup.classList.add(popupStyle);
  popupDescr.innerText = text;

  // clear if existing other
  const existPopups = document.querySelectorAll('.game-popup');
  if (existPopups.length > 0) {
    existPopups.forEach(popup => {
      popup.remove();
    });
  }

  target.parentNode.append(popup);

  // closing popup
  popupClose.addEventListener('click', () => {
    popup.remove();
  })

  const timeout = setTimeout(() => {
    popup.remove();
    clearTimeout(timeout);
  }, 5000)

  window.addEventListener('keydown', ev => {
    if (ev.key === 'Escape') {
      popup.remove();
    }
  })

  // calculate X offset
  let tailOffsetPersent = 2;
  tailOffsetPersent = right ? tailOffsetPersent * (-1) : tailOffsetPersent;
  const computedTarget = window.getComputedStyle(target);
  let targetMidXPx = parseFloat(computedTarget.left) + parseFloat(computedTarget.width) / 2;
  targetMidXPx = right ? targetMidXPx : targetMidXPx - popup.offsetWidth;

  const targetLeftPersent = (targetMidXPx / target.parentNode.offsetWidth * 100 + tailOffsetPersent).toFixed(2) + '%';
  popup.style.left = targetLeftPersent;

  // calculate Y offset
  let modalOffsetPersent = 7;
  modalOffsetPersent = top ? modalOffsetPersent : modalOffsetPersent * (-1);
  let targetMidYPx = parseFloat(computedTarget.bottom) + parseFloat(computedTarget.height) / 2;
  targetMidYPx = top ? targetMidYPx : targetMidYPx - popup.offsetHeight;

  const targetBottomPersent = (targetMidYPx / target.parentNode.offsetHeight * 100 + modalOffsetPersent).toFixed(2) + '%';
  popup.style.bottom = targetBottomPersent;
}

function showTargetOnBar(gameNumber, targetId) {
  document.getElementById(`game-bar-game-${gameNumber}-target-${targetId}`).classList.add('show');
}

function addTargetInStash(targetId) {
  if (foundTargetsIds.includes(targetId)) return;

  foundTargetsIds.push(targetId);
}

function writeGameNames(game, name) {
  gameNames = gameNames.filter(el => el.game !== game);
  gameNames.push({ game, name });
}

async function sendGameResult(gameNumber, time) {
  const userNamePopup = document.getElementById('popup-user-name');
  userNamePopup.innerText = '..generating..';

  const path = '/api/';
  const body = JSON.stringify({ type: gameNumber, time, })

  const response = await fetch(path,
    {
      method: 'POST',
      body,
      headers: { 'Content-Type': 'application/json' }
    })

  const data = await response.json();

  if (data.success) {
    userName = data.username;
    userNamePopup.innerText = userName;
    writeGameNames(gameNumber, userName);
  } else if (data.message === 'unluckiest guy on earth') {
    sendGameResult();
    return;
  } else {
    userNamePopup.innerText = '..something wrong..';
  }
}

function gameResetParams() {
  startTimeMs, currentTime, gameTimeMs = 0;
  timerInterval = null;
  foundTargetsIds = [];
}

function gameFinish() {
  if (foundTargetsIds.length < QUANTITY_TARGETS) return;

  stopTimer();

  gameTimeMs = `${new Date().getTime() - startTimeMs}`;
  gameTimeMs = gameTimeMs.slice(0, -3) + '.' + gameTimeMs.slice(-3);

  sendGameResult(currentGameNum, gameTimeMs);

  const timeout = setTimeout(() => {
    openPage('popup-game-finish');
    Howler.stop();

    clearInterval(timeout);
  }, 5000)
}

function gameInit() {
  const gameExitBtn = document.getElementById('btn-exit');
  const gameStartBtns = document.querySelectorAll('.game-menu-card');
  const gameStartPopupBtn = document.getElementById('popup-game-start-btn');
  const gameFinishPopupBtn = document.getElementById('popup-game-finish-btn');
  const gameTimeOverPopupBtn = document.getElementById('popup-time-over-btn');

  const gameTargets = document.querySelectorAll('.game-target');

  gameExitBtn.addEventListener('click', () => {
    stopTimer();
    closePage('popup-game-finish');
    closeAllGames();
    closeGameControll();
    openPage('game-menu');
    Howler.stop()
  })

  gameStartBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      closePage('game-menu');
      openPage('popup-game-start');
      currentGameNum = parseInt(btn.dataset.game);
      openGame(currentGameNum);
      openGameControll(currentGameNum);
      renderTimer(GAME_TIME);
    })
  });

  gameStartPopupBtn.addEventListener('click', () => {
    closePage('popup-game-start');
    gameResetParams();
    startTimer();
    startTimeMs = new Date().getTime();
    if (isSoundOn) soundMain.play();
  })

  gameFinishPopupBtn.addEventListener('click', () => {
    closePage('popup-game-finish');
    closeAllGames();
    closeGameControll();
    openPage('game-menu');
  })

  gameTimeOverPopupBtn.addEventListener('click', () => {
    closePage('popup-time-over');
    closeAllGames();
    closeGameControll();
    openPage('game-menu');
  })

  gameTargets.forEach(target => {
    const params = {
      id: parseInt(target.dataset.id),
      descr: target.dataset.descr,
      top: target.dataset.position.includes('top'),
      right: target.dataset.position.includes('right'),
    }

    target.addEventListener('click', () => {
      embedGamePopup(target, params.top, params.right, params.descr);
      showTargetOnBar(currentGameNum, params.id);
      addTargetInStash(params.id);
      gameFinish();
      soundBlob.play();
    })
  });
}

function gamePostcardsInit() {
  const gameExitBtn = document.getElementById('btn-exit-postcard');
  const gameCards = document.querySelectorAll('.game-postcard-card');
  const gameStartBtn = document.getElementById('game-menu-btn-generator');
  const gameStartPopupBtn = document.getElementById('popup-postcard-start-btn');
  const gameFihishPopupBtn = document.getElementById('popup-postcard-finish-btn');

  gameExitBtn.addEventListener('click', () => {
    closePage('popup-postcard-finish');
    closePage('game-postcard');
    openPage('game-menu');
  })

  gameCards.forEach(card => {
    card.addEventListener('click', () => {
      openPage('popup-postcard-finish');
    })
  })

  gameStartBtn.addEventListener('click', () => {
    closePage('game-menu');
    openPage('game-postcard');
    
    if (!isPostcardsOpened) {
      openPage('popup-postcard-start');
      isPostcardsOpened = true;
    }
  })

  gameStartPopupBtn.addEventListener('click', () => {
    closePage('popup-postcard-start');
  })

  gameFihishPopupBtn.addEventListener('click', () => {
    closePage('popup-postcard-finish');
    closePage('game-postcard');
    openPage('game-menu');
  })
}

// leaderboard
function createTableUser(rank, name, time, highlight = false) {
  const rankEl = document.createElement('div');
  const nameEl = document.createElement('div');
  const timeEl = document.createElement('div');

  rankEl.classList.add('table-cell');
  nameEl.classList.add('table-cell');
  timeEl.classList.add('table-cell');

  if (highlight) {
    rankEl.classList.add('table-foot-cell');
    nameEl.classList.add('table-foot-cell');
    timeEl.classList.add('table-foot-cell');
  }

  rankEl.innerText = rank;
  nameEl.innerText = name;
  timeEl.innerText = time;

  return [rankEl, nameEl, timeEl];
}

function renderTable(userList = []) {
  const table = document.getElementById('table-main');
  table.replaceChildren();

  userList.forEach(user => {
    createTableUser(user.rank, user.username, user.game_time).forEach(userEl => {
      table.append(userEl);
    })
  });
}

function renderPreviewUser(rank = '', name = '', time = '') {
  const table = document.getElementById('table-foot');
  table.replaceChildren();

  if (rank + name + time == '') return;

  createTableUser(rank, name, time, true).forEach(userEl => {
    table.append(userEl);
  })
}

async function getLeaderboard(game, name, offset) {
  const path = `/api/?type=${game}&username=${name}&offset=${offset}`;

  const response = await fetch(path);
  const data = await response.json();

  const userList = data.result.leaderboard;
  const user = data.result.user || '';

  return { userList, user }
}

async function renderLeaderboard(game, name, offset) {
  const btnLeft = document.getElementById('leaderboard-controll-btn-left');
  const btnRight = document.getElementById('leaderboard-controll-btn-right');
  btnLeft.setAttribute('disabled', true);
  btnRight.setAttribute('disabled', true);

  const tableWrapper = document.getElementById('table-main-wrapper');
  tableWrapper.classList.remove('empty');
  tableWrapper.classList.add('loading');

  const data = await getLeaderboard(game, name, offset);
  const userList = data.userList;
  const user = data.user;

  if (offset > 0) btnLeft.removeAttribute('disabled');
  btnRight.removeAttribute('disabled');
  tableWrapper.classList.remove('loading');

  if (userList.length > 0) {
    renderTable(userList);
  } else {
    renderTable();
    btnRight.setAttribute('disabled', true);
    tableWrapper.classList.add('empty');
  }

  if (typeof (user) === 'object') {
    renderPreviewUser(user.rank, user.username, user.game_time);
  } else {
    renderPreviewUser()
  }
}

function leaderboardInit() {
  const btnClose = document.getElementById('leaderboard-exit');
  const btnOpen = document.getElementById('popup-game-finish-btn-leaderboard');
  const btnsGame = document.querySelectorAll('.leaderboard-pick-btn');
  const btnLeft = document.getElementById('leaderboard-controll-btn-left');
  const btnRight = document.getElementById('leaderboard-controll-btn-right');

  const step = 20;
  let offset = 0;
  let game = currentGameNum;
  let name = gameNames.find(el => el.game === game).name || '';

  btnClose.addEventListener('click', () => {
    closePage('leaderboard');
    openPage('page-game');
    openPage('game-menu');
  })

  btnOpen.addEventListener('click', () => {
    btnsGame.forEach(btnEl => {
      btnEl.classList.remove('active');
    });

    game = currentGameNum;
    name = gameNames.find(el => el.game === game).name || '';

    closePage('popup-game-finish');
    closePage('page-game');
    closeAllGames();
    closeGameControll();
    openPage('leaderboard');
    renderLeaderboard(game, name, offset);

    btnsGame[game - 1].classList.add('active');
  })

  btnsGame.forEach(btn => {
    btn.addEventListener('click', () => {

      btnsGame.forEach(btnEl => {
        btnEl.classList.remove('active');
      });

      btn.classList.add('active');

      offset = 0;
      game = parseInt(btn.dataset.game);
      name = gameNames.find(el => el.game === game).name || '';

      renderLeaderboard(game, name, offset);
    })
  });

  btnLeft.addEventListener('click', () => {
    offset = offset > 0 ? offset - step : 0;

    renderLeaderboard(game, name, offset);
  })

  btnRight.addEventListener('click', () => {
    offset += step;

    renderLeaderboard(game, name, offset);
  })
}

const QUANTITY_GAMES = 4;
const QUANTITY_TARGETS = 5;
const GAME_TIME = 180;

let soundMain = null;
let soundBlob = null;
let isSoundOn = true;
let isSoundInit = false;

let isPostcardsOpened = false;

let currentGameNum = 1;
let startTimeMs, currentTime, gameTimeMs = 0;
let timerInterval = null;
let foundTargetsIds = [];
let userName = '';
let gameNames = [{ game: 1, name: '', }, { game: 2, name: '', }, { game: 3, name: '', }, { game: 4, name: '', }];

document.addEventListener('DOMContentLoaded', () => {
  initSimpleBar();

  calculateMinHeight();
  startPageInit();
  gamePostcardsInit();
  gameInit();
  leaderboardInit();

  renderTable();
  renderPreviewUser()
})
